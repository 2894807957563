<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />

        <h2 class="brand-text text-primary ml-1">
          Vuexy
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Forgot password V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Forgot Password? 🔒
          </b-card-title>
          <b-card-text
            v-if="!isSubmitFormSuccess"
            class="mb-2"
          >
            Enter your email and we'll send you instructions to reset your password
          </b-card-text>

          <b-card-text
            v-if="isSubmitFormSuccess"
            class="mb-2"
          >
            Enter your confirmation code.
          </b-card-text>

          <!-- form -->
          <validation-observer
            v-if="!isSubmitFormSuccess"
            ref="simpleRules"
          >
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
            >
              <b-form-group
                label="Email"
                label-for="forgot-password-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="forgot-password-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="forgot-password-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-overlay
                :opacity="0.5"
                :show="loading"
                rounded="sm"
                :blur="true"
              >
                <b-button
                  type="submit"
                  variant="primary"
                  block
                >
                  Send reset link
                </b-button>
              </b-overlay>
            </b-form>
          </validation-observer>

          <validation-observer
            v-if="isSubmitFormSuccess"
            ref="simpleRules"
          >
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="codeSubmit"
            >
              <b-form-group
                label="Verification Code"
                label-for="verification-code"
              >
                <validation-provider
                  #default="{ errors }"
                  name="verification-code"
                  rules="required"
                >
                  <b-form-input
                    id="verification-code"
                    v-model="token"
                    :state="errors.length > 0 ? false:null"
                    name="verification-code"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-overlay
                :opacity="0.5"
                :show="loading"
                rounded="sm"
                :blur="true"
              >
                <b-button
                  type="submit"
                  variant="primary"
                  block
                >
                  Confirm
                </b-button>
              </b-overlay>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{name:'auth-login-v2'}">
              <feather-icon icon="ChevronLeftIcon" /> Back to login
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import {
  BRow, BCol, BLink, BCardTitle, BCardText, BImg, BForm, BFormGroup, BFormInput, BButton, BOverlay,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import store from '@/store/index'

const AuthRepository = RepositoryFactory.get('auth')

export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return {
      userEmail: '',
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      // validation
      required,
      email,
      isSubmitFormSuccess: false,
      token: '',
      loading: false,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.submitForm()
        }
      })
    },
    async submitForm() {
      try {
        this.loading = true
        const payload = { email: this.userEmail }
        await AuthRepository.forgotPasswordEmailSubmit(payload)
        this.isSubmitFormSuccess = true
        this.showSuccessMessage('Password Reset Link sent successfully')
        this.loading = false
      } catch (e) {
        this.convertAndNotifyError(e)
        this.loading = false
      }
    },
    codeSubmit() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.submitVerifyCodeForm()
        }
      })
    },
    async submitVerifyCodeForm() {
      try {
        this.loading = true
        await AuthRepository.forgotPasswordTokenVerify(this.token)
        this.loading = false
        this.$router.replace(`/reset-password/${this.userEmail}/${this.token}`)
      } catch (e) {
        this.convertAndNotifyError(e)
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss">
    @import '@core/scss/vue/pages/page-auth.scss';
</style>
